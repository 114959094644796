<template>
    <page-header-wrapper>
		<template slot="extra">
			<a-button @click="reback" type="primary"><a-icon type="rollback" />返回</a-button>
		</template>
        <a-card :bordered="false">
            <vue-ueditor-wrap v-model="content" :config="myConfig"></vue-ueditor-wrap>
            <div id="submit">
                <a-button @click="cancel">取消</a-button>
                <a-button type="primary" id="btn" @click="submit">提交</a-button>
            </div>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue'
    import VueUeditorWrap from 'vue-ueditor-wrap'
    import {} from '@/components'
    import {
        getList,
        getDetail,
        eidtInfo
    } from '@/api/agreement'
    import { getUrlKey,UEDITOR_HOME_URL,serverUrl } from '@/utils' 
    var disabled = true;

    export default {
        name: '',
        components: {
            VueUeditorWrap
        },
        data() {
            return {
                id:'',
                content: '',
                myConfig: {
                    // 编辑器不自动被内容撑高
                    autoHeightEnabled: false,
                    // 初始容器高度
                    initialFrameHeight: 480,
                    // 初始容器宽度
                    initialFrameWidth: '100%',
                    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
                    serverUrl: serverUrl,
                    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                    UEDITOR_HOME_URL: UEDITOR_HOME_URL
                },
            }
        },
        created(){
            this.id = getUrlKey('id')
            console.log('this.id',this.id)
            if(this.id){
                this.getDetail(this.id)
            }
        },
        methods: {
           getDetail(id){
                let params = {
                    id:id
                }
                getDetail(params).then(res =>{
                    console.log('res',res)
                    this.content = res.data.content
                })
            },
            cancel(){
                this.$router.push({path:'/operateManage/agreeList?p='+Number(getUrlKey('p'))||1})
            },
            submit(){
                let id = this.id
                let content = this.content
                if(!content){
                    this.$message.info('请输入内容')
                }else{
                    if(disabled){
                        disabled = false
                        eidtInfo({id:id,content:content}).then(res =>{
                            if(res.code==200){
								this.$message.info('提交成功')
								setTimeout(()=>{
								    disabled = true
								    this.$router.push({path:'/operateManage/agreeList?p='+Number(getUrlKey('p'))||1})
								},1000)
							}else{
								message.error(res.msg)
							}
                        })
                    }
                }
            },
            getDate(date, dateString) { //时间筛选
                this.queryParam.time = dateString
                this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0]: ''
                this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1] : ''
            }, 
			reback(){
				this.$router.go(-1)
			}
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD hh:mm:ss')
                } else {
                    return t
                }
            }
        }
    }
</script>


<style scoped>
#submit{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
#btn{
    margin-left:20px;
}
</style>